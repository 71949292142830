<span class="form-label" *ngIf="title">{{ title }}</span>
<mat-form-field class="example-chip-list mt-3">
	<mat-chip-list #hospitalItem aria-label="includedItem">
		<ng-container *ngIf="!isSingle">
			<mat-basic-chip
				*ngFor="let content of selectedData"
				[selectable]="selectable"
				[removable]="true"
				(removed)="remove(content)"
				class="chip-list"
			>
				{{ content }}
				<mat-icon matChipRemove class="chip-list">close</mat-icon>
			</mat-basic-chip>
		</ng-container>

		<ng-container *ngIf="isSingle && selectedSingleData !== ''">
			<mat-basic-chip
				[selectable]="selectable"
				[removable]="true"
				(removed)="remove()"
				class="chip-list"
			>
				{{ selectedSingleData }}
				<mat-icon matChipRemove class="chip-list">close</mat-icon>
			</mat-basic-chip>
		</ng-container>
		<ng-container *ngIf="!numberOnly">
			<input
				[placeholder]="placeholder"
				#keywordInput
				matInput
				[formControl]="excludeControl"
				[matAutocomplete]="hospitalItemAuto"
				[matChipInputFor]="hospitalItem"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="isSingle ? selectSingleText($event) : selectText($event)"
			/>
		</ng-container>
		<ng-container *ngIf="numberOnly">
			<input
				[placeholder]="placeholder"
				#keywordInput
				matInput
				[formControl]="excludeControl"
				[matAutocomplete]="hospitalItemAuto"
				[matChipInputFor]="hospitalItem"
				[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
				(matChipInputTokenEnd)="isSingle ? selectSingleText($event) : selectText($event)"
				type="number"
				min="0"
			/>
		</ng-container>
	</mat-chip-list>
	<mat-icon matSuffix class="closeIcon" *ngIf="selectedData.length > 0" (click)="clear()">close</mat-icon>
	<mat-autocomplete
		#hospitalItemAuto="matAutocomplete"
		(optionSelected)="selectText($event, 'recent')"
		class="app-autocomplete"
	>
		<mat-option style="color: #c2c2c2" *ngIf="recentSelectedValues.length > 0">RECENTLY USED</mat-option>
		<mat-option *ngFor="let contact of recentSelectedValues" [value]="contact">
			<mat-icon style="color: #c2c2c2">access_time</mat-icon>
			{{ contact }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
