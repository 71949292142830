import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
	selector: 'app-more-filter-expand-item',
	templateUrl: './more-filter-expand-item.component.html',
	styleUrls: ['./more-filter-expand-item.component.css']
})
export class MoreFilterExpandItemComponent implements OnInit {
	@Input() selectedData: Array<any> | string = [];
	@Input() title: Array<any> = [];
	@Input() disabled: boolean = false;
	@Input() single: boolean = false;
	@Output() clear = new EventEmitter();
	@Output() selectedDataChange = new EventEmitter();
	@Output() omitChange = new EventEmitter();
	@Input() itemName: any = '';
	@Input() itemValue: any = '';
	removable = true;
	constructor(private renderer: Renderer2) {}

	ngOnInit(): void {}
	get chipData() {
		return Array.isArray(this.selectedData) ? this.selectedData : this.selectedData ? [this.selectedData] : [];
	}
	triggerAutoFocus(eleId: string) {
		const element = this.renderer.selectRootElement(eleId);
		setTimeout(() => element.focus(), 100);
	}
	clearFilter() {
		if (this.single) {
			this.selectedData = Array.isArray(this.selectedData) ? [] : '';
			this.selectedDataChange.emit(this.selectedData);
			this.clear.emit();
		} else {
			this.clear.emit();
		}
	}
	remove(item) {
		if (Array.isArray(this.selectedData)) {
			this.selectedData = this.selectedData.filter((el) => {
				if (this.itemValue) {
					return el[this.itemValue] !== item[this.itemValue];
				}
				return el !== item;
			});
		} else {
			this.selectedData = '';
			this.clear.emit();
		}
		this.selectedDataChange.emit(this.selectedData);
		this.omitChange.emit();
	}
}
